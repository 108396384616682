import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/images/resource/sidebar-logo.webp'
import logonew from '../../assets/images/logo.webp'
import { ConfigContext } from '../../configContext'

function Main() {
    const config = useContext(ConfigContext);
    console.log(config);
    const location = useLocation();

    const [active, setActive] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled, setScrolled] = useState(false);
    const [Nav, setNav] = useState(true);

    const handleScroll = () => {

        const offset = window.scrollY;
        setlastscrool(offset);

        if (offset > 1000 && offset < lastscrool) {
            setNav(true);
            setScrolled(true);
        }

        else if (offset > 1000) {
            setNav(false)
        }

        else if (offset > 200) {
            setNav(true);
            setScrolled(true);
        }

        else {
            setScrolled(false);
            setNav(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    return (
        <>
            {active &&
                <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
            }
            <section className={`hidden-bar ${active && "visible-sidebar"}`} >
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close" onClick={() => setActive(false)} > </span></div>
                    <div className="logo-box">
                        <Link to="/" title="Caravan - Uzbek & Turkish Cusine">
                            <img src={logo} alt="Caravan - Uzbek & Turkish Cusine" title="Caravan - Uzbek & Turkish Cusine" />
                        </Link>
                    </div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li className={location.pathname === "/" ? "current" : ""}>
                                <Link to="/">Home</Link>
                            </li>
                            <li className={location.pathname === "/menu" ? "current" : ""}>
                                <Link to={ config.config.orderonline } target="_blank">Menu</Link>
                            </li>
                            <li className={location.pathname === "/about" ? "current" : ""}>
                                <Link to="/about">About Us</Link>
                            </li>
                            <li className={location.pathname === "/ourchefs" ? "current" : ""}>
                                <Link to="/ourchefs">Our chefs</Link>
                            </li>
                            <li className={location.pathname === "/contact" ? "current" : ""}>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul> 
                    </div>

                    <h2>Visit Us</h2>
                    <ul className="info">
                        <li>{ config.config.address }</li>
                        <li>
                            <span dangerouslySetInnerHTML={{ __html: config.config.schedule }}></span>
                        </li>
                        <li><Link to={`mailto:${config.config.email}`}>{ config.config.email }</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Booking request</div>
                        <div className="bk-no"><Link to={`tel:${config.config.phone}`}>{ config.config.phone }</Link></div>
                    </div>

                </div>
            </section>
            {Nav &&
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i> { config.config.address }</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        <li><Link to={`tel:${config.config.phone}`}><i className="icon far fa-phone"></i>{ config.config.phone }</Link> </li>
                                        <li><Link to={`mailto:${config.config.email}`}><i className="icon far fa-envelope"></i> { config.config.email }</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener" aria-label="Navigation">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="logo-box">
                                    <div className="logo">
                                        <Link to="/" title="Caravan - Uzbek & Turkish Cusine">
                                            <img src={logonew} alt="" title="Caravan - Uzbek & Turkish Cusine" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="nav-box clearfix">

                                    <div className="nav-outer clearfix">
                                        <nav className="main-menu">
                                            <ul className="navigation clearfix">
                                                <li className="current">
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li>
                                                    <Link to={ config.config.orderonline } target="_blank">Menu</Link>
                                                </li>
                                                <li>
                                                    <Link to="/about">About Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ourchefs">Our chefs</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact">Contact</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>

                                <div className="link link-btn">
                                    <Link to={ config.config.orderonline } target="_blank" className="theme-btn btn-style-one clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one">order online</span>
                                            <span className="text-two">or pick up</span>
                                        </span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>
            }
        </>
    )
}

export default Main