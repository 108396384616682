import { useContext } from 'react'
import { Link } from "react-router-dom";
import { ConfigContext } from '../../configContext'
import bg1 from "../../assets/images/background/bg-1.webp";
import bg2 from "../../assets/images/background/bg-2.webp";
import offerimg1 from "../../assets/images/resource/offer-image-1.webp";
import offerimg2 from "../../assets/images/resource/offer-image-2.webp";
import offerimg3 from "../../assets/images/resource/offer-image-3.webp";

function Offer() {
	const config = useContext(ConfigContext);
	const offers = [
		{
			image: offerimg1,
			title: "Turkish Breakfast",
			description: "Coming Soon",
			link: "/menu",
		},
		{
			image: offerimg2,
			title: "Main Dishes",
			description: "",
			link: "/menu",
		},
		{
			image: offerimg3,
			title: "Desserts",
			description: "",
			link: "/menu",
		},
	];

	return (
		<>
			<section className="we-offer-section">
				<div className="left-bot-bg">
					<img src={bg1} alt="Ornament" title="Ornament" />
				</div>
				<div className="right-top-bg">
					<img src={bg2} alt="Ornament" title="Ornament" />
				</div>
				<div className="auto-container">
					<div className="title-box centered">
						<div className="subtitle">
							<span>Flavors for royalty</span>
						</div>
						<div className="pattern-image">
							<img
								src={require("../../assets/images/icons/separator.svg").default}
								alt="Caravan - Uzbek & Turkish Cusine"
							/>
						</div>
						<h2>Welcome to our Halal Restaurant</h2>
						<div className="text">
							Our restaurant is dedicated to serving dishes that strictly adhere
							to Halal guidelines, ensuring both taste and trust in every bite.
						</div>
					</div>
					<div className="row justify-content-center clearfix">
						{offers.map((offer, index) => (
							<div
								key={index}
								className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
								<div
									className="inner-box wow fadeInUp"
									data-wow-duration="1500ms"
									data-wow-delay={`${index * 300}ms`}>
									<div className="image">
										<Link to={ config.config.orderonline } target="_blank"  aria-label={offer.title}>
											<img src={offer.image} alt={offer.title} />
										</Link>
									</div>
									<h3>
										<Link to={ config.config.orderonline } target="_blank"  aria-label={offer.title}>{offer.title}</Link>
									</h3>
									<div className="text">{offer.description}</div>
									<div className="more-link">
										<Link to={ config.config.orderonline } target="_blank"  aria-label={offer.title} name={offer.title}>view menu</Link>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
}

export default Offer;
