import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ConfigContext } from '../../configContext'
import Slideone from '../../assets/images/main-slider/slider-1.webp';
import Slidetwo from '../../assets/images/main-slider/slider-2.webp';
import Slidethree from '../../assets/images/main-slider/slider-3.webp';
import bookicon1 from '../../assets/images/resource/book-icon-1.webp';
import 'swiper/swiper.min.css';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';


SwiperCore.use([Navigation]);

function Banner() {
    const config = useContext(ConfigContext);
    return (
        <Swiper
            className="banner-section banner-section-coustm"
            loop={true}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }} // Use "navigation" with lowercase, and place it outside the SwiperSlide elements
        >
            <div className="banner-container">
                <div className="banner-slider"> 
                    <div className="swiper-wrapper">
                        {[Slideone, Slidetwo, Slidethree].map((image, index) => (
                            <SwiperSlide key={index} className="swiper-slide slide-item img-fluid" style={{ backgroundImage: `url(${image})` }}>
                                <div className="auto-container">
                                    <div className="content-box">
                                        <div className="content">
                                            <div className="clearfix">
                                                <div className="inner">
                                                    <div className="subtitle">
                                                        <span>delightful experience</span>
                                                    </div>
                                                    <div className="pattern-image">
                                                        <img src={require('../../assets/images/icons/separator.svg').default} alt="Caravan - Uzbek & Turkish Cusine" />
                                                    </div>
                                                    <h1><span>{index === 0 ? 'Flavors Inspired by' : index === 1 ? 'Where every flavor' : 'For the love of'}<br />{index === 0 ? 'the Traditions' : index === 1 ? 'tells a story' : 'delicious food'}</span></h1>
                                                    <div className="text">Come with family & feel the joy of Uzbek & Turkish food</div>
                                                    <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div className="link">
                                                            <Link to={ config.config.orderonline } target="_blank"  className="theme-btn btn-style-two clearfix">
                                                                <span className="btn-wrap">
                                                                    <span className="text-one">view our menu</span>
                                                                    <span className="text-two">view our menu</span>
                                                                </span>
                                                            </Link>
                                                            <Link to={ config.config.orderonline } target="_blank" className="mt-3 mt-md-0 theme-btn btn-style-one clearfix">
                                                                <span className="btn-wrap">
                                                                    <span className="text-one">order online</span>
                                                                    <span className="text-two">or pick up</span>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                </div>
            </div>
            <div className="swiper-button-prev">
                <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
                <span className="fal fa-angle-right"></span>
            </div>
            <div className="book-btn">
                <Link to="/reservation" className="theme-btn" aria-label="Book Now" name="book_now">
                    <span className="icon">
                        <img src={bookicon1} alt="Book Now" title="Book Now" />
                    </span>
                    <span className="txt">book a table</span>
                </Link>
            </div>
        </Swiper>
    );
}

export default Banner;
